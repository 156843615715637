<template>
  <div>
    <h3 class="profile">
      About Me
    </h3>
    <p>
      I love to write code! I would totally be coding on my own even if I
      couldn't do this professionally. I've really worked well on my own.
      However, working with a team is so enjoyable to me. And this year, due
      to COVID19, I've been introduced to working collaboratively via Zoom.
      The experience of getting to learn and work with many other talented
      individuals to achieve the same goals has been amazing! I was drawn to
      coding and computer science purely out of curiosity. I am now totally
      hooked!
      <a
        href="https://docs.google.com/document/d/18t87PlpK-ww_PE8Or8BWujdb3QeVHrm6fj6cYdcxvuI/edit?usp=sharing"
        target="_blank"
        className="purp"
      >
        Here is a link to my resume
      </a>
      . My professional background is in the casino industry. To my pleasant
      surprise, the hospitality skills I already possessed really proved
      valuable working in a team environment. I am sure that experience
      would cross over to communicating with clients as well. In my free
      time, I mostly enjoy spending time with my family and traveling. I
      also love tennis, video games, skateboarding, and of course coding!
    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style>
h1 {
    font-size: 7em;
    color: rgba(139, 119, 155, .8);
    padding-top: 20%;
    padding-bottom: 8%;
    text-shadow: 2px 2px 2px rgb(66, 22, 77);
}

h3 {
  color: rgb(66, 22, 77);
  font-size: 4em;
  text-shadow: 1.5px 1.5px 1.5px rgb(66, 22, 77);
  padding-bottom: 15%;
}

p {
  color: rgb(66, 22, 77);
  padding: 1% 8% 5%;
}

.purp {
  color: rgb(139, 119, 155);
  text-decoration: none;
  font-weight: bold;
  text-shadow: 0.5px 0.5px 0.5px rgb(66, 22, 77);
}

.purp:hover {
  color: rgb(129, 45, 150);
}

.profile {
  padding-top: 14%;
  padding-bottom: 1%;
}

</style>